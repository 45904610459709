<template>
  <en-drawer :model-value="modelValue" title="联保申请" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" :loading="form.loading">
      <en-form-item label="项目">
        <en-table :data="table.data" @selection-change="table.selection.change" class="w-full">
          <en-table-column type="selection"></en-table-column>
          <en-table-column label="项目名称" prop="surface.fullMessage"></en-table-column>
        </en-table>
      </en-form-item>
      <en-form-item label="联保说明" prop="comment">
        <en-input v-model="form.data.comment" type="textarea"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :disabled="!form.data.surfaces.length" :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    workorderId: Number,
    method: Function
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean'
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          if (this.workorderId) {
            const res = await this.ajax('GET /enospray/workorder/:workOrderId', { paths: [this.workorderId] })
            this.table.data = res.data[0].surfaces.filter((item) => {
              return (
                ['NA', 'R'].includes(item.jointWarrantyApplicationStatus?.code!) &&
                item.surface?.code.split('_')[1] !== 'S' &&
                item.surface?.code.split('_')[1] !== 'B'
              )
            })
          }
        } else {
          this.form.init()
        }
      }
    }
  },

  config: {
    children: {
      table: {
        data: [] as EnospraySprayDefinitions['WorkOrderSurfaceDto'][],
        children: {
          selection: {
            change(rows: EnospraySprayDefinitions['WorkOrderSurfaceDto'][]) {
              this.form.data.surfaces = rows
            }
          }
        }
      },
      form: {
        data: {
          comment: '',
          surfaces: [] as EnospraySprayDefinitions['WorkOrderSurfaceDto'][]
        },
        rules: {
          comment: [{ required: true, message: '请填写联保说明' }]
        },
        ajax: {
          submit: {
            action: 'POST /enospray/workorder/jointwarrantyapply',
            loading: true,
            validate: true,
            params(params) {
              params.payload.workOrderId = this.workorderId
              params.payload = this.form.data
            }
          }
        }
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {
            await this.form.submit()
            this.footer.cancel.click()
            return this.method?.()
          }
        }
      }
    }
  }
})
</script>
